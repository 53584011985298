@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    //   &::-webkit-scrollbar {
    //     width: 8px;
    //   }
       
    //   &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   }
       
    //   &::-webkit-scrollbar-thumb {
    //     background-color: #008060;
    //     outline: 1px solid slategrey;
    //   }
      font-family: 'Poppins', sans-serif !important;
    .d-block {
        display: block;
    }
    .d-inline-block {
        display: inline-block;
    }
    .d-flex {
        display: flex;
    }
    .d-none {
        display: none;
    }
    .d-inline-flex {
        display: inline-flex;
    }
    .align-self-center{
        align-self: center;
    }
    .align-items-center {
        align-items: center;
    }
    .align-items-baseline {
        align-items: baseline;
    }
    .align-items-end {
        align-items: flex-end;
    }
    .align-items-start {
        align-items: flex-start;
    }
    .justify-content-center {
        justify-content: center;
    }
    .justify-content-start {
        justify-content: flex-start;
    }
    .justify-content-end {
        justify-content: flex-end;
    }
    .justify-content-between {
        justify-content: space-between;
    }
    .justify-content-around {
        justify-content: space-around;
    }
    .m-0 {
        margin: 0rem !important;
    }
    .m-1 {
        margin: 0.25rem !important;
    }
    .m-2 {
        margin: 0.5rem !important;
    }
    .m-3 {
        margin: 1rem !important;
    }
    .m-4 {
        margin: 1.5rem !important;
    }
    .m-5 {
        margin: 3rem !important;
    }
    .ml-0 {
        margin-left: 0rem !important;
    }
    .ml-1 {
        margin-left: 0.25rem !important;
    }
    .ml-2 {
        margin-left: 0.5rem !important;
    }
    .ml-3 {
        margin-left: 1rem !important;
    }
    .ml-4 {
        margin-left: 1.5rem !important;
    }
    .ml-5 {
        margin-left: 3rem !important;
    }
    .mr-0 {
        margin-right: 0rem !important;
    }
    .mr-1 {
        margin-right: 0.25rem !important;
    }
    .mr-2 {
        margin-right: 0.5rem !important;
    }
    .mr-3 {
        margin-right: 1rem !important;
    }
    .mr-4 {
        margin-right: 1.5rem !important;
    }
    .mr-5 {
        margin-right: 3rem !important;
    }
    .mb-0 {
        margin-bottom: 0rem !important;
    }
    .mb-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-3 {
        margin-bottom: 1rem !important;
    }
    .mb-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-5 {
        margin-bottom: 3rem !important;
    }
    .mt-0 {
        margin-top: 0rem !important;
    }
    .mt-1 {
        margin-top: 0.25rem !important;
    }
    .mt-2 {
        margin-top: 0.5rem !important;
    }
    .mt-3 {
        margin-top: 1rem !important;
    }
    .mt-4 {
        margin-top: 1.5rem !important;
    }
    .mt-5 {
        margin-top: 3rem !important;
    }
    .mb-n3{
        margin-bottom: -1.4rem !important;
    }
    .p-0 {
        padding: 0rem !important;
    }
    .p-1 {
        padding: 0.25rem !important;
    }
    .p-2 {
        padding: 0.5rem !important;
    }
    .p-3 {
        padding: 1rem !important;
    }
    .p-4 {
        padding: 1.5rem !important;
    }
    .p-5 {
        padding: 3rem !important;
    }
    .pl-0 {
        padding-left: 0rem !important;
    }
    .pl-1 {
        padding-left: 0.25rem !important;
    }
    .pl-2 {
        padding-left: 0.5rem !important;
    }
    .pl-3 {
        padding-left: 1rem !important;
    }
    .pl-4 {
        padding-left: 1.5rem !important;
    }
    .pl-5 {
        padding-left: 3rem !important;
    }
    .pr-0 {
        padding-right: 0rem !important;
    }
    .pr-1 {
        padding-right: 0.25rem !important;
    }
    .pr-2 {
        padding-right: 0.5rem !important;
    }
    .pr-3 {
        padding-right: 1rem !important;
    }
    .pr-4 {
        padding-right: 1.5rem !important;
    }
    .pr-5 {
        padding-right: 3rem !important;
    }
    .pb-0 {
        padding-bottom: 0rem !important;
    }
    .pb-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-3 {
        padding-bottom: 1rem !important;
    }
    .pb-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-5 {
        padding-bottom: 3rem !important;
    }
    .pb-32px{
        padding-bottom: 32px !important;
    }
    .pt-0 {
        padding-top: 0rem !important;
    }
    .pt-1 {
        padding-top: 0.25rem !important;
    }
    .pt-2 {
        padding-top: 0.5rem !important;
    }
    .pt-3 {
        padding-top: 1rem !important;
    }
    .pt-4 {
        padding-top: 1.5rem !important;
    }
    .pt-5 {
        padding-top: 3rem !important;
    }
    .text-uppercase {
        text-transform: uppercase;
    }
    .text-8 {
        font-size: 8px !important;
    }
    .text-12 {
        font-size: 12px !important;
    }
    .text-14 {
        font-size: 14px !important;
    }
    .text-16 {
        font-size: 16px !important;
    }
    .text-18 {
        font-size: 18px !important;
    }
    .text-20 {
        font-size: 20px !important;
    }
    .text-22 {
        font-size: 22px !important;
    }
    .text-26{
        font-size: 26px !important;
    }
    .text-30{
        font-size: 30px !important;
    }
    .font-weight-900 {
        font-weight: 900;
    }
    .font-weight-800 {
        font-weight: 800;
    }
    .font-weight-700 {
        font-weight: 700;
    }
    .font-weight-600 {
        font-weight: 600;
    }
    .font-weight-500 {
        font-weight: 500;
    }
    .font-weight-400 {
        font-weight: 400;
    }
    .color-56{
        color: rgba(0, 0, 0, 0.56);
    }
    .text-right{
        text-align: right;
    }
    .text-left{
        text-align: left;
    }
    .text-center{
        text-align: center;
    }
    .text-muted{
        color: #8C9196 !important;
    }
    .flex-wrap{
        flex-wrap: wrap !important;
    }
    .border{
        border: 1px solid #C9CCCF;
    }
    .border-bottom{
        border-bottom: 1px solid #D2D5D8 !important;
    }
    .border-0{
        border: none !important;
    }
    .rounded-4{
        border-radius: 4px;
    }
    .Polaris-Tabs__Title{
        outline: none !important;
        &::after{
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .thumb-border-0{
        .Polaris-Thumbnail{
            border: none !important;
            img{
                margin: 0px;
                padding: 0px !important;
            }
            &::after{
                padding: 10px;
            }
        }
    }
    .text-yellow{
        color: #916A00;
    }
    .h-100{
        height: 100% !important;
    }
    .h-48px{
        height: 48px !important;
    }
    .w-48px{
        width: 48px !important;
    }
    .w-100{
        width: 100% !important;
    }
    .w-50{
        width: 50% !important;
    }
    .w-94{
        width: 94% !important;
    }
    .h-36px{
        height: 36px;
    }
    .w-36{
        width: 36px;
    }
    .text-green{
        color: #008060;
    }
    .text-light-green{
        color: #06C270;
    }
    .text-truncate{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .text-nowrap{
        white-space: nowrap;
    }
    .w-214{
        width: 214px;
    }
    .react-switch-bg{
        width: 52px !important;
        height: 22px !important;
        // background-color: #d4d4d4;
    }
    .react-switch-handle{
        // background-color: #008060 !important;
        top: 2px !important;
        left: 2px;
        width: 18px !important;
        height: 18px !important;
    }
    .bg-aqua-light{
        background-color: #CCDDFF;
    }
    .w-45{
        width: 45%;
    }
    .red-light{
        color: #FF3B3B;
    }
    .blue-light{
        color:#0063F7;
    }
    .yellow-light{
        color: #FF8800;
    }
}
.popover-field{
    border-radius: 4px !important;
    .Polaris-TextField__Backdrop{
        background-color: #E4E5E7;
        border: none;
        border-radius: 0px 4px 4px 0px ;
    }
}
.green-badge{
    .Polaris-Tag{
        background-color: #E3FFF1;
        color: #06C270;
    }
}
.orange-badge{
    .Polaris-Tag{
        background-color: #fff2e3;
        color: #FF8800;
    }
}
.card-disconnect{
    .Polaris-Button{
        background: transparent;
        border: none;
        box-shadow: none !important;
        color: #D82C0D;
    }
}

.action-popover{
    .Polaris-Button--plain{
        box-shadow: none !important;
        padding-left: 10px !important;
    }
}
.action-popover-2{
    .Polaris-Button--plain{
        box-shadow: none !important;
        padding: 0px !important;
        .Polaris-Button__Text{
            font-size: 12px;
        }
    }
}
.Polaris-Card{
    box-shadow: inset 0px -1px 0px #e1e3e5;
    border: 1px solid #D2D5D8 !important;
}
.Header-icon{
    .Polaris-Icon{
        width: 64px;
        height: 64px;
    }
}
.filterBox{
    .Polaris-Button{
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
        border-radius: 4px !important;
        padding: 5px 8px 5px 12px !important;
        border: 1px solid #BBBFC3 !important;
    }
}
.img-preview {
    .Polaris-Stack__Item {
        width: 100px;
        height: 100px;
        .Polaris-Thumbnail {
            width: auto;
            height: auto;
            border: none;
            border-radius: 0px;
            img {
                width: 100px;
                height: 100px;
            }
        }
    }
}
.card-widget{
    border-radius: 8px;
    padding: 25px;
    // width: 195px;
}
.avatar-container {
    .Polaris-Avatar {
        width: 80px;
        height: 80px;
    }
}
.social-widget-big{
    width: 90%;
    height: auto;
    min-width: 640px;
    .Polaris-Card{
        height: auto !important;
        width: 100%;
    }
    &.border-none{
        .Polaris-Card{
            border: none !important;
            box-shadow: none;
        }
    }
    &.dotted-line{
        .Polaris-Card{
            border: 2px dashed #2C6ECB !important;
        }
    }
}
.social-widget{
    // width: 45%;
    height: auto;
    // min-width: 307px;
    .Polaris-Card{
        height: auto !important;
        width: 100%;
    }
    &.border-none{
        .Polaris-Card{
            border: none !important;
            box-shadow: none;
        }
    }
    &.dotted-line{
        .Polaris-Card{
            border: 2px dashed #2C6ECB !important;
        }
    }
}
.setting-widget{
    // width: 45%;
    height: auto;
    // min-width: 307px;
    .Polaris-Card{
        height: auto !important;
        width: 100%;
    }
    &.border-none{
        .Polaris-Card{
            border: none !important;
            box-shadow: none;
        }
    }
    &.dotted-line{
        .Polaris-Card{
            border: 2px dashed #2C6ECB !important;
        }
    }
}
.Profile-section {
    margin: 23px 0px;
}
.Polaris-Button {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
}
.Polaris-IndexTable__TableCell{
    .Polaris-Button {
        box-shadow: none !important;
    }
}
.Polaris-Tabs__Panel{
    box-shadow: none !important;
}
.btn {
    color: #fff !important;
    background: #008060 !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
}
.thumbNail-icon{
    .Polaris-Thumbnail{
        min-width: auto;
        min-height: auto;
        width: 15px;
        height: 18px;
        border: none !important;
        img{
            margin: 0px;
        }
    }
}
.setting-logo{
    .Polaris-Thumbnail{
        min-width: auto;
        min-height: auto;
        width: 100%;
        height: 200px;
        img{
            margin: 0px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.btn-icon{
    .Polaris-Button{
        background: #e2e2e2 !important;
        border: none !important;
        outline: none !important;
        width: 35px;
        height: 35px;
        box-shadow: none !important;
        padding-right: 8px;
        svg{
            color: #696969;
        }
    }
}
.shopifyLogo{
    .Polaris-Thumbnail{
        min-width: auto;
        min-height: auto;
        width: 35px;
        height: 35px;
        border: none !important;
        img{
            margin: 0px;
        }
    }
}
.thumbNail-icon-name{
    .Polaris-Thumbnail{
        min-width: auto;
        min-height: auto;
        width: 60px;
        height: 14px;
        border: none !important;
        img{
            margin: 0px;
        }
    }
}
.clientLogo{
    .Polaris-Thumbnail{
        width: 175px;
        height: 110px;
        display: flex;
        align-items: center;
        align-self: center;
        padding: 10px !important;
        img{
            position: static;
            margin: 0px !important;
        }
    }
}
.notification-button{
    margin-left: 2rem;
    margin-right: 2rem;
    .Polaris-Icon{
        margin: 0px 2rem;
    }
}
.main-layout-bg{
    .Polaris-Card{
        height: calc(100vh - 172px);
        overflow: auto;
    }
}
.tabs-main-layout-bg{
    .Polaris-Card{
        height: calc(100vh - 240px);
        overflow: auto;
    }
}

.primary-button{
    .Polaris-Button{
        background-color: #3E7BFA !important;
        border-radius: 4px !important;
        width: 300px;
        height: 64px;
        box-shadow: none !important;
        color: white;
    }
}

.Polaris-Button:focus,
.Polaris-Button:hover {
    outline: none !important;
}

hr {
    border-top: 1px solid #D2D5D8 !important;
}

.notification-list{
    .Polaris-ResourceItem__Media{
        margin-right: 1rem;
    }
    .Polaris-ResourceItem__Container{
        padding-bottom: 0px !important;
    }
}

.new-notification{
    background-color: #EBF9FC;
    .Polaris-ResourceItem__Media{
        &::before{
            content: '';
            width: 12px;
            height: 12px;
            background-color: #2C6ECB;
            color: #2C6ECB;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            top: 29px;
            left: 5px;
        }
    }
}

.notification-hr{
    margin-right: -20px;
}

.text-center {
    text-align: center;
}
.Polaris-FormLayout__Item {
    margin-top: 0 !important;
    label {
        color: #6d7175;
        font-weight: 500;
    }
    span {
        color: #202123;
    }
}
.ratings {
    .Polaris-Thumbnail {
        width: 30px !important;
        height: 20px !important;
        min-width: auto !important;
        border: none !important;
        .Polaris-Icon {
            margin: 0px !important;
            svg {
                color: #ff8800;
            }
            img {
                margin-left: 0px !important;
            }
        }
    }
    .Polaris-Button{
        box-shadow: none !important;
    }
}

.header-start{
    .Polaris-Thumbnail{
        background-color: transparent;
        width: 20px !important;
        img{
            margin: auto 0px;
        }
    }
}

.Polaris-Scrollable--vertical{
    border-top: none !important;
}

.initials-avatar{
    .Polaris-Avatar{
        border: 2px solid white;
        background-color: #91E0D6 !important;
        &.Polaris-Avatar--sizeMedium{
            width: 4.5rem;
        }
        .Polaris-Avatar__Initials{
            .Polaris-Avatar__Svg{
                text{
                    font-size: 16px !important;
                    font-weight: 500;
                    color: #002D2D;
                }
            }
        }
    }
}
.start-page-section {
    .logo {
        text-align: center;
        padding: 64px 0px 48px 0px;
    }
    .main-area {
        background: #fff;
        max-width: 760px;
        margin: auto auto;
        overflow: hidden;
        width: auto;
    }
    @media only screen and (max-width: 991px) {
        .main-area {
            max-width: 691px;
        }
        .img-area{
            .img-text1{
                font-size: 24px; 
            }
        }
        .notification-button{
            margin-left: 1.2rem !important;
            margin-right: 1.2rem !important;
            .Polaris-Icon{
                margin: 0px 1.2rem !important;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .main-area {
            max-width: 580px;
        }
        .img-area{
            .img-text1{
                font-size: 18px; 
            }
        }
        .notification-button{
            margin-left: 1rem !important;
            margin-right: 1rem !important;
            .Polaris-Icon{
                margin: 0px 1rem !important;
            }
        }
    }
    @media only screen and (max-width: 576px) {
        .main-area {
            max-width: 380px;
        }
        .img-area{
            .img-text1{
                font-size: 14px; 
            }
        }
        .notification-button{
            margin-left: 0.8rem !important;
            margin-right: 0.8rem !important;
            .Polaris-Icon{
                margin: 0px 0.8rem !important;
            }
        }
    }
    .img-area {
        .main-img {
            padding: 36px 0px 32px 0px;
            text-align: center;
        }
        .img-text1 {
            font-size: 28px;
            font-weight: 600;
            color: #202123;
            text-align: center;
            padding: 16px 0;
        }
        .img-text2 {
            font-size: 14px;
            // color:#000;
            text-align: center;
            color: #6d7175;
            width: 80%;
            margin: auto;
        }
    }

    .form-area {
        padding: 0 20px;
        .form-text {
            color: #202123;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 28px;
        }

        .main-form {
            display: flex;
            margin-bottom: 2rem;
            .form-area {
                width: 50%;
                .Polaris-Choice {
                    padding: 2px;
                    margin-top: 10px;
                }
                .Polaris-Choice__Label {
                    font-size: 14px;
                    font-weight: 600;
                    color: #202223;
                }
                .form-check-text {
                    padding: 2px;
                    color: #6d7175;
                    margin-left: 28px;
                }
            }
            .preview-area {
                width: 50%;
                height: 250px;
                background: #fff5ea;
                display: flex;
                justify-content: center;

                div {
                    align-self: center;
                }
            }
        }
    }
    .btn,
    .Polaris-Button {
        color: #fff !important;
        background: #008060 !important;
        float: right;
        margin: 0px 20px 36px;
    }
}
.label-text {
    font-size: 14px;
    // color:#000;
    text-align: center;
    color: #6d7175;
}
.email-text {
    color: #2c6ecb;
}
.popover-field{
    .Polaris-TextField__Input{
        // background-color: #E4E5E7;
        color: #202223;
        border-radius: 4px 0px 0px 4px;
    }
}
.popover-list-box{
    height: 200px;
    .list{
        margin: 10px 4px;
        color: #202223;
    }
}
.email-preview{
    .Polaris-Card{
        box-shadow: none !important;
        border: 1px solid #E1E3E5 !important;
    }
}
.starIcon{
    width: 15px;
}
.banned-btn{
    .Polaris-Button{
        height: 30px !important;
        border-radius: 5px !important;
    }
}
#PolarisPortalsContainer{
    .Polaris-Popover__Wrapper{
        width: 320px;
    }
}
// .Polaris-PositionedOverlay{
//     top: 55px !important;
// }
.order-placed{
    .Polaris-Tag{
        background-color: #FFF8E5;
        padding: 7px 12px !important;
        border-radius: 4px !important;
        .Polaris-Tag__TagText{
            color: #FF8800;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}
.order-fullfill{
    .Polaris-Tag{
        background-color: #E3FFF1;
        padding: 7px 12px !important;
        border-radius: 4px !important;
        .Polaris-Tag__TagText{
            color: #06C270;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}
.order-cancel{
    .Polaris-Tag{
        background-color: #FFE5E5;
        padding: 7px 12px !important;
        border-radius: 4px !important;
        .Polaris-Tag__TagText{
            color: #FF3B3B;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}
.send-req{
    .Polaris-Button{
        border: none !important;
        background: none !important;
        color: #2C6ECB;
    }
}
.cancel-req{
    .Polaris-Button{
        border: none !important;
        background: none !important;
        color: #D82C0D;
    }
}
.Polaris-Navigation__Text{
    font-weight: 500;
}
.Polaris-Popover__Wrapper{
    width: 180px;
    border: 1px solid #C9CCCF !important;
    .Polaris-ActionList__Actions{
        li{
            button{
                .Polaris-ActionList__Text{
                    color: #8C9196;
                }
            }
            &:last-child{
                button{
                    .Polaris-ActionList__Text{
                        color: #D72C0D;
                    }
                }
            }
        }
    }
}

.img-tag{
    .Polaris-Tag{
        min-height: 2.4rem;
        padding: 0 0.5rem;
        background-color: #E4E5E7;
    }
    .Polaris-Tag__TagText{
        display: flex;
        align-items: center;
        .Polaris-Icon{
            margin-left: 0px;
            width: 14px;
            height: 14px;
        }
    }
}

.half-star-spacing{
    img{
        margin-left: 0px;
    }
}
.btn-link {
    .Polaris-Button {
        border: none !important;
        box-shadow: none !important;
        padding: 0px !important;
        &:hover {
            background: transparent !important;
            .Polaris-Button__Text {
                text-decoration: underline;
            }
        }
    }
}
.btn-link.btn-edit {
    .Polaris-Button__Text {
        color: #2c6ecb !important;
    }
}
.btn-link.btn-delete {
    .Polaris-Button__Text {
        color: #d82c0d !important;
    }
}
.review-modal1 {
    .Polaris-TextContainer .review-modal-text1 {
        font-size: 16px;
        font-weight: 600 !important;
        color: #000;
        padding: 2px 0;
    }
    .Polaris-TextContainer .review-modal-text2 {
        margin: 0 !important;
        font-size: 14px;
        color: #6d7175;
        padding: 2px 0;
    }
    .review-radio {
        margin-top: 26px;
        margin-bottom: 20px;
        .Polaris-Choice {
            margin-right: 36px;
        }
    }
    // .review-main-modal2{
    //     .Polaris-Button{
    //         float: right !important;
    //         color:#fff !important;
    //         background:#008060 !important;
    //         display:inline-block;
    //         margin: 17px 0px 21px;
    //     }

    // }
    .review-select {
        .review-select-text {
            font-size: 16px;
            font-weight: 600 !important;
            color: #000;
            padding: 20px 0;
        }
        .review-select-text1 {
            font-size: 16px;
            font-weight: 600 !important;
            color: #000;
            padding: 2px 0;
            margin-top: 36px;
        }
        .review-select-text2 {
            font-size: 14px;
            color: #6d7175;
            padding: 2px 0;
            margin-bottom: 16px;
        }
    }
}
.Polaris-Tabs__Panel{
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);   
}
.dropzone-design{
    .Polaris-DropZone{
        min-height: 15.5rem;
    }
    .Polaris-Labelled--hidden{
        .Polaris-DropZone__Container{
            display: flex !important;
            justify-content: center !important;
            .Polaris-Stack{
                .Polaris-Stack__Item:first-child{
                    display: none;
                }
                .Polaris-Stack__Item{
                    margin-top: 0px;
                    .Polaris-DropZone-FileUpload__Button{
                        border: none !important;
                        box-shadow: none !important;
                        padding: 0px !important;
                        background: none !important;
                        color: #2C6ECB;
                    }
                }
            }
        }
    }
}
.dropzone-design-after{
    .Polaris-DropZone{
        min-height: 15.5rem;
    }
    .Polaris-Labelled--hidden{
        .Polaris-DropZone__Container{
            display: flex !important;
            justify-content: center !important;
            .Polaris-Stack{
                .Polaris-Stack__Item{
                    margin-top: 0px;
                    .Polaris-DropZone-FileUpload__Button{
                        border: none !important;
                        box-shadow: none !important;
                        padding: 0px !important;
                        background: none !important;
                        color: #2C6ECB;
                    }
                }
            }
        }
    }
}
.onboarding-review{
    .Polaris-DisplayText{
        font-weight: 600;
        font-size: 28px;
    }
    .Polaris-EmptyState__ImageContainer{
        margin-bottom: 12px;
    }
}
.review-list-section {
    padding: 20px;

    .review-head {
        font-weight: 600;
        font-size: 28px;
        color: #202223;
        padding: 28px 0;
    }
    .review-stats {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 36px;
        .review-stats-each {
            width: 25%;
            padding: 0 10px;
            &:nth-child(1){
             padding-left: 0;
            }
            &:nth-child(4){
                padding-right: 0;
                }

            .review-each {
                background: #fff;
                border-radius: 8px;
            }
        }
    }
    .review-stats-each-no {
        font-size: 42px;
        padding: 20px 20px 10px;
        color: #008060;
        span {
            font-weight: 800;
        }
    }
    .review-stats-each-text {
        font-size: 14px;
        padding: 10px 20px 20px;
        color: #6d7175;
    }
    .review-list-tabs {
        padding: 0 8px;
        border-bottom: 1px solid #6d7175;
        box-sizing: border-box;
        margin-bottom: 16px;

        .review-list-tab1 {
            display: inline-block;
            padding: 16px 8px;
            font-size: 14px;
            color: #6d7175;
        }
        .review-list-tab2 {
            display: inline-block;
            padding: 8px;
            font-size: 14px;
            color: #6d7175;
        }
        .active {
            color: #202223;
            border-bottom: 2px solid #008060;
        }
    }
    .review-list-area {
        .review-list-head {
            background: #fff;
            display: flex;
            justify-content: space-between;
            padding: 13px 16px;
            align-items: center;
            border-radius: 10px 10px 0px 0px;
            border: 1px solid #D2D5D8;
            border-bottom: none;
            .review-head-no {
                font-weight: 600;
                font-size: 16px;

            }
        }
        .review-table {
            .Polaris-Card {
                border-radius: 0px !important;
                box-shadow: none !important;
                border-radius: 0px 0px 10px 10px !important;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .main-area {
        max-width: 691px;
    }
    .img-area{
        .img-text1{
            font-size: 24px !important; 
        }
    }
}
@media only screen and (max-width: 768px) {
    .main-area {
        max-width: 580px;
    }
    .img-area{
        .img-text1{
            font-size: 18px !important; 
        }
    }
    .start-page-section{
      .form-area{
        .main-form{
            display: block !important;
            margin-bottom: 0px;
            .form-area{
                width: 100% !important;
                margin-bottom: 3rem;
            }
            .preview-area{
                width: 100% !important;
                margin-bottom: 2rem;
            }
        }
      }   
    } 
    
}
@media only screen and (max-width: 576px) {
    .main-area {
        max-width: 380px;
    }
    .img-area{
        .img-text1{
            font-size: 14px !important; 
        }
    }
}
.review-table{
    table{
        thead{

        }
        tbody{
            tr{
                td{
                    &:first-child{
                        vertical-align: baseline;
                    }
                    &:nth-child(2), &:nth-child(3),&:nth-child(4){
                        vertical-align: baseline;
                    }
                }
            }
        }
    }
}
.success-context-bar{
    .Polaris-Frame__ContextualSaveBar{
        bottom: 0;
        top: auto !important;
        .Polaris-Frame-ContextualSaveBar{
            background-color: #008060 !important;
            .Polaris-Frame-ContextualSaveBar__LogoContainer{
                display: none !important;
            }
            .Polaris-Frame-ContextualSaveBar__Contents{
                .Polaris-Button{
                    background-color: #008060 !important;
                }
            }
        }
    }
}
.danger-context-bar{
    .Polaris-Frame__ContextualSaveBar{
        bottom: 0;
        top: auto !important;
        .Polaris-Frame-ContextualSaveBar{
            background-color: #D82C0D !important;
            .Polaris-Frame-ContextualSaveBar__LogoContainer{
                display: none !important;
            }
            .Polaris-Frame-ContextualSaveBar__Contents{
                .Polaris-Button{
                    background-color: #D82C0D !important;
                }
            }
        }
    }
}
.order-card{
    .Polaris-IndexTable__Table{
        thead{
            tr{
                .Polaris-IndexTable__TableHeading{
                    &:last-child{
                        text-align: right !important;
                    }
                }
            }
        }
    }
}
.icon-big{
    .Polaris-Icon{
        width: 30px;
        height: 30px;
        svg{
            fill: #FDAC42;
        }
    }
}
// .danger-context-bar {
//     .Polaris-Frame__Main{
//       padding-top: 1.6rem;
//     }
//   }
.shop-name-dropdown {
    display: none;
}
.shop-name{
    display: block;
}
@media only screen and (max-width: 767px) {
    .shop-name-dropdown {
      display: block;
    }
    .shop-name{
        display: none;
    }
}

.position-center{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-tabs-position{
    position: sticky;
    top: 0px;
    z-index: 4;
    background: #F6F6F7;
}

$color: #5C8DF6;
$colorRight: darken(#5C8DF6, 15%);
$colorLeft: darken(#5C8DF6, 5%);
$shadow: #DBE3F4;

.boxes {
    --size: 32px;
    --duration: 800ms;
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 3);
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    margin-top: calc(var(--size) * 1.5 * -1);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    .box {
        width: var(--size);
        height: var(--size);
        top: 0;
        left: 0;
        position: absolute;
        transform-style: preserve-3d;
        &:nth-child(1) {
            transform: translate(100%, 0);
            animation: box1 var(--duration) linear infinite;
        }
        &:nth-child(2) {
            transform: translate(0, 100%);
            animation: box2 var(--duration) linear infinite;
        }
        &:nth-child(3) {
            transform: translate(100%, 100%);
            animation: box3 var(--duration) linear infinite;
        }
        &:nth-child(4) {
            transform: translate(200%, 0);
            animation: box4 var(--duration) linear infinite;
        }
        & > div {
            --background: #{$color};
            --top: auto;
            --right: auto;
            --bottom: auto;
            --left: auto;
            --translateZ: calc(var(--size) / 2);
            --rotateY: 0deg;
            --rotateX: 0deg;
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--background);
            top: var(--top);
            right: var(--right);
            bottom: var(--bottom);
            left: var(--left);
            transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
            &:nth-child(1) {
                --top: 0;
                --left: 0;
            }
            &:nth-child(2) {
                --background: #{$colorRight};
                --right: 0;
                --rotateY: 90deg;
            }
            &:nth-child(3) {
                --background: #{$colorLeft};
                --rotateX: -90deg;
            }
            &:nth-child(4) {
                --background: #{$shadow};
                --top: 0;
                --left: 0;
                --translateZ: calc(var(--size) * 3 * -1);
            }
        }
    }
}

.add-border-radius-quill{
    .ql-container{
        border-radius: 5px !important;
    }
    .ql-editor{
        p{
            font-family: 'Poppins', sans-serif !important;
        }
    }
}

@keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(200%, 0);
    }
}

@keyframes box2 {
    0%{
        transform: translate(0, 100%);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

@keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }
    100% {
        transform: translate(0, 100%);
    }
}

@keyframes box4 {
    0%{
        transform: translate(200%, 0);
    }
    50% {
        transform: translate(200%, 100%);
    }
    100% {
        transform: translate(100%, 100%);
    }
}

html {
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

// Center & dribbble
// body {
//     min-height: 100vh;
//     font-family: Roboto, Arial;
//     color: #ADAFB6;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #F9FBFF;
//     .dribbble {
//         position: fixed;
//         display: block;
//         right: 20px;
//         bottom: 20px;
//         img {
//             display: block;
//             height: 28px;
//         }
//     }
// }

.review-title {
  text-transform: capitalize;
}
.customer-name {
  text-transform: capitalize;
}
.cursorp {
    cursor: pointer;
}