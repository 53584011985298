.dropzone-design-widget{
    .Polaris-DropZone{
        min-height: 15.5rem;
    }
    .Polaris-Labelled--hidden{
        .Polaris-DropZone__Container{
            .Polaris-Stack{
                .Polaris-Stack__Item:first-child{
                    display: flex;
                    justify-content: center;
                    img{
                        width: 23px;
                    }
                }
                .Polaris-Stack__Item{
                    .Polaris-DropZone-FileUpload__Button{
                        box-shadow: none !important;
                        background: none !important;
                        color: #3E7BFA;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
.playIcon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid white;
    padding: 10px;
    border-radius: 40px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multiple-line-trancate{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.two-line-trancate{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.green-bar{
    .Polaris-ProgressBar__Indicator{
        background-color: #05A660;
    }
}
.yellow-bar{
    .Polaris-ProgressBar__Indicator{
        background-color: #E57A00;
    }
}
.red-bar{
    .Polaris-ProgressBar__Indicator{
        background-color: #E53535;
    }
}
.black-overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.productReviewSelect{
    .Polaris-Select__Content{
        min-height: 3.3rem !important;
    }
}
.bg-green-light{
    background-color: #06C270;
}
.review-popup{
    &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -5px;
        left: 18px;
        transform: rotate(45deg);
        background: #fff;
    }
}
.image-gallery{
    height: 100vh;
    .image-gallery-content{
        height: 100%;
    }
    .image-gallery-slide-wrapper{
        // height: calc(100% - 30%);
        height: 75vh;
        .image-gallery-swipe{
            height: 100%;
            .image-gallery-slides{
                height: 100%;
                .image-gallery-slide{
                    height: 100%;
                    >div{
                        height: 100%;
                        .video-wrapper{
                            height: 100%;
                            .video-container{
                                height: 100%;
                                video{
                                    height: 100%;
                                }
                            }
                            .image-gallery-image{
                                // height: 100%;
                                padding: 5rem;
                                max-height: 75vh;
                            }
                        }
                    }
                }
            }
        }
        .image-gallery-slides{
            height: 100%;
            .image-gallery-slide{
                height: 100%;
                >div{
                    height: 100%;
                    .video-wrapper{
                        height: 100%;
                        .video-container{
                            height: 100%;
                            video{
                                height: 100%;
                            }
                        }
                        .image-gallery-image{
                            // height: 100%;
                            padding: 5rem;
                            max-height: 75vh;
                        }
                    }
                }
            }
        }
        .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
            height: 30px;
            width: 60px;
        }
        .image-gallery-play-button, .image-gallery-fullscreen-button{
            display: none;
        }
    }
    .image-gallery-thumbnails-wrapper{
        height: 25vh;
        .image-gallery-thumbnails{
            padding-bottom: 0;
            height: 100%;
            .image-gallery-thumbnail{
                height: 100px;
                .image-gallery-thumbnail-inner{
                    // height: 100%;
                    .image-gallery-thumbnail-image{
                        height: 92px;
                        position: relative;
                        object-fit: cover;
                    }
                    [src^="data"]::before{
                        content: '';
                        position: absolute;
                        top: 30px;
                        left: 30px;
                        background-image: url('../img/half-star.svg');
                        background-size: 50px 50px;
                        display: block;
                        width: 22px;
                        height: 10px;
                        margin: 10px 5px 0 10px;
                    }
                }
            }
        }
    }
}

.video-player-thumbnail{
    >div{
        height: auto !important;
        video{
            height: 200px !important;
        }   
    }
}

.video-container{
    video{
        margin: auto;
    }
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1s linear infinite;
	animation: spinner 1s linear infinite;
}

.skeleton-padding-0{
    .Polaris-SkeletonPage__Page{
        padding: 0px !important;
    }
}

.sorting-dropdown{
    .Polaris-SkeletonPage__Header{
        .Polaris-SkeletonPage__TitleWrapper{
            .Polaris-SkeletonPage__SkeletonTitle{
                margin-left: auto;
            }
        }
    }
}

.loadingspinner {
	pointer-events: none;
	width: 3.2em;
	height: 3.2em;
	border: 0.4em solid transparent;
	border-color: #eee;
	border-top-color: #3E67EC;
	border-radius: 50%;
	animation: loadingspin 1s linear infinite;
}

@keyframes loadingspin {
	100% {
			transform: rotate(360deg)
	}
}

.custom-skeleton-card{
    width: 100%;
    height: 100%;
    background-color: #EFF0F1;
    border-radius: 4px;
}

.card-100-per{
    .Polaris-Card{
        height: 100%;
    }
}

.dropzone-design-widget{
    .filepond--root{
        contain: size !important;
        .filepond--list{
            display: flex;
            .filepond--item{
                width: calc(50% - 0.5em);
            }
        }
    }
    .filepond--drop-label{
        background-color: #fbfbfb !important;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        border: 2px dashed #e1dddd !important;
        min-height: 7.75em !important;
    }
}

.upload-svg{
    width: 30px;
    height: 30px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}