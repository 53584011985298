@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bg-color{
  background-color: #00000000 !important;
}
.primary-button button{
      background-color: #3E7BFA;
      border-radius: 4px !important;
      width: 300px;
      height: 64px;
      box-shadow: none !important;
      color: white;
      border: none;
      /* // width: 163px;
      // height: 30px;
      // right: 397px; */
      
      top: calc(50%-30px/2 + 191px);
      font-size: 24px;
      line-height: 29px;
  }

  .danger-context-bar div:first-child {
    min-height: unset;
  }

  .danger-context-bar #AppFrameMain{
    padding-top: 0px;
  }

  .success-context-bar div:first-child {
    min-height: unset;
  }

  .success-context-bar #AppFrameMain{
    padding-top: 0px;
  }

  .react-switch-bg svg {
    display: none;
  }

  .no-review {
    text-align: center;
    font-weight: 600;
  }

  .bg-none{
    background: none;
  }

 /* Review request Email Template css*/

 .review-request-table {
  font-family: ui-sans-serif, system-ui, -apple-system, 'Segoe UI', sans-serif; width: 100%;
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
