.background-wrpper{
    background-color: #ffffff; border-radius: 4px; font-size: 16px; line-height: 24px; padding: 48px; text-align: left; color: #1f2937; box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.footer-first{
    font-size: 12px; padding-left: 24px; padding-right: 24px; text-align: center; color: #4b5563;
}
.footer-second{
    margin: 0; margin-bottom: 4px; color: #3b82f6; text-decoration: none; -webkit-font-smoothing: auto; -moz-osx-font-smoothing: auto;
}
.logo{
    border: 0; line-height: 100%; max-width: 100%; vertical-align: middle;
}
//coupon --------------------------------------------------------------------------------
.coupon-header-1,.coupon-header-2{
    margin: 0; margin-bottom: 24px; -webkit-font-smoothing: auto; -moz-osx-font-smoothing: auto;
    .coupon-header-1-link{
        margin: 0; margin-bottom: 4px; color: #6366f1; text-decoration: none;
    }
}
.coupon-highlight-bar{
    background-color: #3b82f6; border-radius: 4px; padding-top: 16px; padding-bottom: 16px; padding-left: 32px; padding-right: 32px; color: #ffffff;
}
.coupon-highlight-header{
    display: inline-block; font-size: 18px; text-align: left; width: 56.66%; margin-right: 8px;
}
.coupon-highlight-header-button{
    display: inline-block; width: 33.333333%;
}
.coupon-highlight-header-button-cover{
    padding: 3px 18px; background-color: #ffffff; border-radius: 4px; text-align: center;box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    .coupon-highlight-header-button-text{
        font-size: 14px; line-height: 100%; padding-top: 12px; padding-bottom: 12px; padding-left: 48px; padding-right: 48px; color: #3b82f6; text-decoration: none; width: 100%;
    }
}
.hover-bg-blue-50:hover {
    background-color: #eff6ff !important;
}
.hover-underline:hover {    
    text-decoration: underline !important;
}
//coupon --------------------------------------------------------------------------------

//emai-verification ---------------------------------------------------------------------
.hover-bg-blue-600:hover {
    background-color: #2563eb !important;
}
.hover-bg-blue-600{
    background-color: #3b82f6
}
.email-verify-button{
    display: block; font-size: 14px; line-height: 100%; padding-top: 9px; padding-bottom: 9px; padding-left: 19px; padding-right: 19px; color: #ffffff; text-decoration: none;
}
//transacation --------------------------------------------------------------------------
.hover-no-underline:hover {
  text-decoration: none !important;
}
.transcation-header-1{
    font-weight: 600; font-size: 24px; margin: 0; margin-bottom: 24px; color: #000000;
}
.transcation-btn{
    background-color: #3b82f6; border-radius: 4px; display: inline-block; font-weight: 600; font-size: 16px; padding-top: 16px; padding-bottom: 16px; padding-left: 24px; padding-right: 24px; text-align: center; color: #ffffff; text-decoration: none;
}
.transaction-header-1-link{
    color: #3b82f6; text-decoration: underline;
}
//promotional ---------------------------------------------------------------
.hover-bg-indigo-700:hover {
    background-color: #4338ca !important;
  }
  .hover-text-blue-400:hover {
    color: #60a5fa !important;
  }
.on-bordeing-img{
    background-image: url('https://images.unsplash.com/photo-1565932887479-b18108f07ffd?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1200&amp;q=80'); background-position: top; background-repeat: no-repeat; background-size: cover; border-radius: 4px; text-align: left;
}
.on-board-header{
    font-size: 36px; margin: 0; margin-bottom: 16px; color: #ffffff;
}
.on-board-description{
    font-size: 18px; line-height: 24px; margin: 0; color: #ffffff;
}
.on-board-btn{
    background-color: #3730a3; border-radius: 4px; display: inline-block; font-weight: 600; font-size: 16px; padding-top: 16px; padding-bottom: 16px; padding-left: 24px; padding-right: 24px; text-align: center; color: #ffffff; text-decoration: none;
}
.promotion-grid-container{
    padding-left: 24px; padding-right: 24px; text-align: left; width: 100%;
}
.promotion-grid{
    background-color: #ffffff; border-radius: 4px; padding: 24px;
}
.promo-date{
    font-size: 14px; margin: 0; margin-bottom: 4px; color: #6b7280;
}
.promo-header{
    font-size: 24px; line-height: 24px; margin: 0; margin-bottom: 24px;
}
.promo-info{
    font-size: 16px; margin: 0; margin-bottom: 24px; color: #1f2937;
}
.promo-btn{
    font-size: 16px; color: #3b82f6; text-decoration: none;
}
.list-container{
    padding-left: 48px; padding-right: 48px; text-align: left;
}
.promo-title{
    font-weight: 600; font-size: 16px; margin: 0; color: #6b7280; text-transform: uppercase;
}
.promo-list-header{
    font-weight: 600; font-size: 20px; margin: 0; margin-bottom: 4px;
}
.promo-footer-msg{
    border-radius: 4px; padding-top: 16px; padding-bottom: 16px; padding-left: 48px; padding-right: 48px; text-align: left;
}
@media (max-width: 600px) {
    .sm-inline-block {
      display: inline-block !important;
    }
    .sm-h-32 {
      height: 32px !important;
    }
    .sm-leading-40 {
      line-height: 40px !important;
    }
}