.widget-customisation-section {
    padding: 20px;

    .widget-head {
        margin: 36px 0px 24px;

        .Polaris-Heading {
            padding-top: 8px;
            font-size: 28px !important;
            font-weight: 600 !important;
        }
    }

    .widget-setting {
        // width: 35%;
        // padding-right: 10px;

        .widget-setting-head {
            padding: 16px 0;

            .Polaris-Heading {
                font-size: 2rem;
            }
        }
        .widget-setting-disclaimer {
            .Polaris-Banner--withinPage {
                padding: 20px !important;
                border-radius: 8px 8px 0px 0px !important;
                margin-top: 16px;
            }
        }
        .widget-setting-deatil {
            background-color: #fff;
            border-radius: 0px 0px 8px 8px;
            padding: 16px 12px;
            .widget-setting-btn {
                .Polaris-Card {
                    padding: 8px 0;
                    border-radius: 0px;
                    box-shadow: none;
                }

                .Polaris-SettingAction__Setting {
                    flex: 1 1 !important;
                }
                
            }
            .selection-lable{
                font-weight: 600px !important;
            }
            .selection-labletext{
                font-size: 12px !important;
                color: #008060 !important;
            }
            .input-labletext{
                font-size: 12px !important;
                color: #8C9196 !important;
            }
        }
    }

    .widget-preview {
        // width: 65%;
        // padding-left: 10px;

        .widget-preview-head {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;

            .Polaris-Heading {
                align-self: center;
                font-size: 2rem;
            }
        }

        .widget-preview-detail {
            background-color: #fafafc;
            padding: 24px;
            margin-top: 16px;
            border-radius: 8px;
            .Polaris-Heading:nth-child(1) {
                font-size: 19px !important;
                font-weight: 700 !important;
                padding-bottom: 12px;
            }

            .widget-rating {
                width: 35%;

                margin-top: 19px;
                margin-right: 5px;
                .widget-rating-detail {
                    padding: 12px;
                    background-color: #fff;
                    border: 0.5 solid #f2f2f5;
                    box-shadow: 0px 1.05556px 2.11111px rgba(40, 41, 61, 0.04),
                        0px 4.22222px 8.44444px rgba(96, 97, 112, 0.16);
                    border-radius: 5px;
                }
                .Polaris-Heading {
                    font-size: 34px !important;
                    font-weight: 800 !important;
                    margin-top: 10px;
                }
                .Polaris-TextStyle--variationSubdued {
                    // font-size: 8px !important;
                    font-weight: 600 !important;
                    color: rgba(0, 0, 0, 0.56) !important;
                }

                .mention {
                    padding-top: 19px;
                    // font-size: 8px !important;
                    font-weight: 600 !important;
                    color: rgba(0, 0, 0, 0.56) !important;
                }
                .review-progress {
                    padding-top: 15px;
                    .progress-each {
                        display: flex;

                        .Polaris-ProgressBar {
                            margin: 0 10px;
                            align-self: center;
                            width: 50% !important;
                        }
                    }
                }
                .customer-img {
                    padding-top: 19px;
                    // font-size: 8px !important;
                    font-weight: 600 !important;
                    color: rgba(0, 0, 0, 0.56) !important;
                }
                .ratings {
                    margin: 10px 0;
                }
                .review-tag {
                    padding: 8px 0 12px;
                    .Polaris-Tag {
                        font-size: 10px !important;
                        margin: 4px;
                        background-color: transparent !important;
                        border-radius: 25px !important;
                        color: rgba(0, 0, 0, 0.72) !important;
                        border: 1px solid rgba(0, 0, 0, 0.72) !important;
                    }
                }
                .img-gallery {
                    margin-top: 8px;
                }
                img {
                    width: 100%;
                }
            }
            .widget-review {
                width: 65%;

                margin-top: 19px;
                margin-left: 5px;
                .widget-review-head {
                    padding: 13px 0;
                    display: flex;
                    justify-content: space-between;
                    .Polaris-TextStyle--variationSubdued {
                        align-self: center;
                    }
                }
                .widget-review-each {
                    position: relative;
                    background-color: #fff;
                    border: 0.5 solid #f2f2f5;
                    box-shadow: 0px 1.05556px 2.11111px rgba(40, 41, 61, 0.04),
                        0px 4.22222px 8.44444px rgba(96, 97, 112, 0.16);
                    border-radius: 5px;
                    padding: 12px;
                    margin-bottom: 13px;

                    .Polaris-Heading {
                        padding-top: 8px;
                    }
                    p {
                        padding-top: 5px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        bottom: -5px;
                        left: 18px;
                        transform: rotate(45deg);
                        background: #fff;
                    }
                }
                img {
                    width: 100%;
                }
                .name-sign {
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(28, 28, 40, 0.08);
                    color: rgba(0, 0, 0, 0.56);
                    font-weight: 800px;
                    border-radius: 50%;
                    margin-left: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}
